<script setup lang="ts">
import { ContainerProps } from "../ProductPage/Support/Container.props"
import { HorizontalNeedHelpProps } from "./HorizontalNeedHelp.props"

const { t } = useI18n()

withDefaults(defineProps<HorizontalNeedHelpProps>(), {
  backgroundColor: "white"
})

const supportCardsData = computed<ContainerProps>(() => {
  return {
    infoCard1: {
      iconType: "call",
      title: t("supportBanner.cards.helpdesk.title"),
      text: t("supportBanner.cards.helpdesk.text"),
      link: t("supportBanner.cards.helpdesk.link"),
      linkText: t("supportBanner.cards.helpdesk.linkText"),
      classTitle: "text-tiffany-400",
      classText: "beaver"
    },
    infoCard2: {
      iconType: "chat",
      title: t("supportBanner.cards.writeUs.title"),
      text: t("supportBanner.cards.writeUs.text"),
      link: t("supportBanner.cards.callPharma.link"),
      linkQuery: t("supportBanner.cards.callPharma.linkQuery", {
        param: helpSubjectParams.consulting_pharma
      }),
      linkText: t("supportBanner.cards.callPharma.linkText"),
      classTitle: "text-tiffany-400",
      classText: "beaver"
    },
    infoCard3: {
      iconType: "mail",
      title: t("supportBanner.cards.callPharma.title"),
      text: t("supportBanner.cards.callPharma.text"),
      link: t("supportBanner.cards.callPharma.link"),
      linkQuery: t("supportBanner.cards.callPharma.linkQuery", {
        param: helpSubjectParams.consulting_pharma
      }),
      linkText: t("supportBanner.cards.callPharma.linkText"),
      classTitle: "text-tiffany-400",
      classText: "beaver"
    }
  }
})
</script>

<template>
  <div
    class="banner-help-horizontal_need_help padded bg-grey-main py-8"
    :class="backgroundColor === 'grey' ? 'bg-grey-main' : 'bg-white'"
  >
    <BannerProductPageSupportContainer
      class="flex flex-col gap-6 md:flex-row"
      v-bind="supportCardsData"
    />
  </div>
</template>
